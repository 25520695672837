<template lang="pug">
v-layout.caption(row) 
  v-flex(xs4)
    v-card.text-xs-center(
      tile
      flat
      :color="mocksent ? 'black' : 'orange'"
      :class="mocksent ? 'font-weight-bold white--text' : ''"
    ) mock sent
  v-flex(xs4)
    v-card.text-xs-center(
      tile
      flat
      :color="mockapproved ? 'black' : 'orange'"
      :class="mockapproved ? 'font-weight-bold white--text' : ''"
    ) mock approved
  v-flex(xs4)
    v-card.text-xs-center(
      tile
      flat
      :color="garmentsreceived ? 'black' : 'orange'"
      :class="garmentsreceived ? 'font-weight-bold white--text' : ''"
    ) received
</template>
<script>
export default {
  props: {
    progress: {
      type: Object,
      required: true
    }
  },
  computed: {
    mocksent () {
      return this.progress.isMockSent
    },
    mockapproved () {
      return this.progress.isMockApproved
    },
    garmentsreceived () {
      return this.progress.isProductsReceived
    },
  }
}
</script>