import moment from 'moment'
import { groupBy, concat } from 'lodash'
import { ProductionInstance } from '@/models'

export default (inputList) => {
  let items = inputList.map(x => x)
  items = addDates(items)

  return items
}

function addDates (items) {
  if (items.length < 1) { return items }

  const weeks = []
  let results = []
  const groupedbyweek = groupBy(items, (pi) => {
    const yr = moment(pi.dates.dropDead).year() 
    const wk = moment(pi.dates.dropDead).week()

    if (!yr || !wk) return 0
    return yr + '-' + wk
  })

  for (const key in groupedbyweek) {
    if (key !== '0') {
      const firstdayofweek = moment().year(key.substr(0, 4)).week(key.substr(5)).weekday(1).format('LL')
      weeks.push({
        date: firstdayofweek,
        pi: groupedbyweek[key]
      })
    } else {
      weeks.push({
        date: false,
        pi: groupedbyweek[key]
      })
    }
  }

  for (const week of weeks) {
    results = concat(results, dateInstance(week.date), week.pi)
  }


  return results
}

function dateInstance (date) {
  const instance = new ProductionInstance({
    schedType: 'date',
  })

  if (!date) {
    instance._tempDate = 0
    return instance
  } else {
    instance._tempDate = date
    return instance
  }
}
