<template lang="pug">
div(v-if="loaded")

  ListSchedule(
    :items="productionInstances"
    @select="select"
  )
    template(slot="line1" slot-scope="{ item }")
      v-layout(row)
        v-flex(xs6) {{item.dashboard.designName}} - {{item.dashboard.jobSubtitle}} -
          span.font-weight-bold {{item.location.template.name}}
        v-flex(xs3) 
          JobProgressBar(:progress="item.dashboard.jobProgress")
        v-flex(xs3).pl-3
          span.font-weight-bold expected:
          template(v-if="item.dates.dropDeadOverride") {{formatDate(item.dates.dropDeadOverride)}}
          template(v-else) {{formatDate(item.dates.dropDead)}}
        
    template(slot="line2" slot-scope="{ item }")
      v-layout(row)
        v-flex(xs8) order: {{item.dashboard.orderDisplayName}} / job#: {{item.dashboard.jobNumber}}

</template>

<script>
import moment from 'moment'
import ListSchedule from './list'
import JobProgressBar from '@/components/JobProgressBar/fangamer'

export default {
  components: { ListSchedule, JobProgressBar },
  data: () => ({ progressToggle: false }),
  computed: {
    productionInstances () {
      const pis = this.$store.getters['productionInstances/items']
      return pis.filter(x => x.location.template)
    },
    loaded () {
      return this.$store.state.productionInstances.loaded
    }
  },
  methods: {
    select (item) {
      // bring up a PI detail
      this.$router.push({ path: '/dashboards/schedule/' + item._id })
    },
    formatDate (date) {
      return date
      if (!date) { return '' }
      return moment(date).format('L')
    }

  },
  async created () {
    console.log('dispatch schedule read only')
    await this.$store.dispatch('dashboards/_LOAD_SCHEDULE_READ_ONLY')
  }
}
</script>
