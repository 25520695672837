<template lang="pug">
div()
  v-list(subheader two-line)
    div(
      v-for='(item, i) in itemsSorted'
      :key='i'
    )
      v-list-tile(
        v-if="item.schedType === 'line'"
        :class="tileClass(item)"
        avatar
      ).list-item
        v-list-tile-avatar(v-if="item.dashboard.image.url" tile)
          img(:src="item.dashboard.image.url")
        v-list-tile-content(@click='select(item)')
          v-list-tile-title
            slot(name="line1" :item="item")
          v-list-tile-sub-title
            slot(name="line2" :item="item")
      v-list-tile.list-item.black.white--text(
        v-if="item.schedType === 'date'"
      )
        v-list-tile-content
          v-list-tile-title(v-if='item._tempDate === 0').font-weight-black.title
            | no expected date set
          v-list-tile-title(v-else).font-weight-black.title
            | expected week of {{ item._tempDate }}

</template>
<script>
import scheduleSort from '../../../helpers/sort-fangamer'

export default {
  props: {
    items: { type: Array }
  },
  computed: {
    itemsSorted () { return scheduleSort(this.items) }
  },
  methods: {
    tileClass (item) {
      if (!item.dashboard.jobProgress.isMockApproved || !item.dashboard.jobProgress.isProductsReceived) { return 'highlight-warning' }
    },
    select (item) {
      // bring up a PI detail
      this.$router.push({ path: '/dashboards/schedule/view/' + item._id })
    }
  }
}
</script>

<style lang="stylus" scoped>
.highlight-warning
  background-color #F8BBD0
.highlight-presscheck
  background-color #69F0AE
</style>
